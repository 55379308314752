import { INTERPRETER_OPTIONS_LIST, INTERPRETER_AUDIO, INTERPRETER_VIDEO } from "../constants/interpreter.constants";
import { GUEST_OPTIONS_LIST, GUEST_INVITE_TEXT, GUEST_INVITE_AUDIO, SUPPORT_OPTIONS_LIST } from "../constants/guest.constants";
import { ADD_GUEST_KEY, ADD_INTERPRETER_KEY, ADD_SUPPORT_KEY, ALLOWED_INVISIT_MENU_ITEMS } from "../constants/tab.constants";
import translations from "../translations";
import { NUID_ID_TYPE } from "../constants/app.constants";

export const getTabSettings = (featureMatrix) => {
    let guestTab = false;
    let audioGuest = false;
    let sms = false;
    let email = false;
    let interpreterTab = false;
    let audio = false;
    let video = false;
    let supportTab = false;
    featureMatrix.forEach((element) => {
        if (GUEST_OPTIONS_LIST.includes(element)) {
            guestTab = true;
            if (element === "guest-by-phone") {
                audioGuest = true;
            } else if (element === "guest-by-email") {
                email = true;
            } else {
                sms = true;
            }
        } else if (INTERPRETER_OPTIONS_LIST.includes(element)) {
            interpreterTab = true;
            if (element === "interpreter-audio") {
                audio = true;
            } else {
                video = true;
            }
        }

        if (SUPPORT_OPTIONS_LIST.includes(element)) {
            supportTab = true;
        }
    })

    return {
        [ADD_INTERPRETER_KEY]: {
            isDefault: interpreterTab,
            enableTab: interpreterTab,
            audio,
            video
        },
        [ADD_GUEST_KEY]: {
            isDefault: !interpreterTab,
            enableTab: guestTab,
            audioGuest,
            sms,
            email
        },
        [ADD_SUPPORT_KEY]: {
            supportTab: supportTab
        }
    };
}

export const getRadioButtonsForInterpreter = (tabSettings) => {
    const getSettings = tabSettings[ADD_INTERPRETER_KEY];
    const RadioButtons = [];
    const getSettingsValue = () => {
        return getSettings.audio ? INTERPRETER_AUDIO : null
    };
    let interpreterDefaultValue = getSettings.video ? INTERPRETER_VIDEO : getSettingsValue();
    if (getSettings.video) {
        RadioButtons.push({
            label: 'Video',
            name: 'interpreterType',
            value: INTERPRETER_VIDEO,
            checked: true
        })
    }
    if (getSettings.audio) {
        RadioButtons.push({
            label: 'Audio',
            name: 'interpreterType',
            value: INTERPRETER_AUDIO,
            checked: !getSettings.video
        })
    }
    return {
        RadioButtons,
        interpreterDefaultValue
    };
}

export const getRadioButtonsForGuest = (tabSettings) => {
    const getSettings = tabSettings[ADD_GUEST_KEY];
    const RadioButtons = [];
    const getSettingsValue = () => {
        return getSettings.audioGuest ? GUEST_INVITE_AUDIO : null
    }
    let guestDefaultValue = (getSettings.email || getSettings.sms) ? GUEST_INVITE_TEXT : getSettingsValue();
    if (getSettings.email || getSettings.sms) {
        RadioButtons.push({
            label: 'Video',
            name: 'guestInvitationType',
            value: GUEST_INVITE_TEXT,
            checked: true
        })
    }
    if (getSettings.audioGuest) {
        RadioButtons.push({
            label: 'Audio',
            name: 'guestInvitationType',
            value: GUEST_INVITE_AUDIO,
            checked: !(getSettings.sms || getSettings.email)
        })
    }
    return {
        RadioButtons,
        guestDefaultValue
    };
}

export const getRadioButtonsForGuestAndPatient = () => {
    const RadioButtons = [];

    RadioButtons.push({
        label: 'Guest',
        name: 'guestPatientInvitationType',
        value: 'GUEST',
        title:'Guest',
        checked: true
    })

    RadioButtons.push({
        label: 'Patient',
        name: 'guestPatientInvitationType',
        value: 'PATIENT',
        title:'Patient',
    })

    return {
        RadioButtons
    };
}

export const getErrorModalProps = (err) => {
    const translation = translations.ERROR_CODES;
    let errorCode;
    if (err && err.response && err.response.data && err.response.data.errors) {
        const errorData = err.response.data.errors;
        if (errorData.length > 0) {
            errorCode = errorData[0].code
        } else {
            errorCode = errorData.code
        }
    } else {
        errorCode = err?.response?.status || err?.code || 500;
    }

    if (translation[errorCode]) {
        return translation[errorCode];
    }

    return null;
}

export const getCapitalizedName = name => {
    let splittedData = name.split(' ');
    splittedData = splittedData.map(val => {
        if (!val) return val;
        return val[0].toUpperCase() + val.substr(1, val.length - 1).toLowerCase();
    });
    return splittedData.join(' ');
}

export const validateAppInitializationDependencies = (dependencies, isDirectory) => {
    let isValidStatus = validateAppDependenciesForMeeting({dependencies, isDirectory});
    if (isDirectory === true) {
        isValidStatus = isValidStatus && validateAppDependenciesisDirectory(dependencies);
    }
    return isValidStatus;
};

const validateAppDependenciesForMeeting = ({dependencies, isDirectory}) => {
    const {
        appSettings: {
            appointment: {
                appointmentRegion = null,
                appointmentVisitType = null,
                provider: { nuid = null } = {}
            } = {},
            menuItems = []
        } = {}
    } = dependencies ?? {};
    const basicValidationStatus = (appointmentRegion !== null && appointmentRegion !== "") &&
                            (appointmentVisitType !== null && appointmentVisitType !== "") &&
                            (nuid !== null && nuid !== "");
    const validMenuItemStatus = (Array.isArray(menuItems) && menuItems.length > 0 && menuItems.every(item => ALLOWED_INVISIT_MENU_ITEMS.includes(item)));
    return isDirectory ? basicValidationStatus : (basicValidationStatus && validMenuItemStatus);
};

const validateAppDependenciesisDirectory = (dependencies) => {
    const {
        directoryConfig: {
            features: {
                directory: {
                    clinicianFilterDetails: {
                        region: regionFilterDetails = null,
                        providerType: providerTypeFilterDetails = null
                    } = {}
                } = {},
                featureFlags: {
                    menuDetails = null
                } = {}
            } = {}
        } = {}
    } = dependencies ?? {};
    return regionFilterDetails !== null && providerTypeFilterDetails !== null && menuDetails !== null;
};

const prepareFirstAndLastName = (fullName) => {
    const nameSplits = fullName.split(" ");
    const [firstName, ...otherDetails] = nameSplits;
    const lastName = otherDetails.join(" ");
    return { firstName, lastName };
};

export const prepareProviderDetails = (setupResponse, uid) => {
    const {
        appointment: {
            appointmentRegion,
            provider: {
                nuid,
                providerName = ""
            }
        }
    } = setupResponse;
    const parsedNameDetails = prepareFirstAndLastName(providerName);
    return {
        id: nuid,
        idType: NUID_ID_TYPE,
        region: appointmentRegion,
        ...parsedNameDetails,
        zoomUid: uid
    };
};