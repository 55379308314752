import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from 'react-bootstrap/Nav';
import AddSupport from "../AddSupport/AddSupport";
import AddGuest from "../AddGuest/AddGuest";
import AddInterpreter from "../AddInterpreter/AddInterpreter";
import TabAction from '../../store/actions/TabAction';
import { prepareInvisitMenuList } from "../../helper/menuHelper";
import { STORE_NAMES } from "../../constants/store.constants";
import { INVISIT_ABRIDGE_KEY,
        INVISIT_INTERPRETER_KEY,
        INVISIT_ADD_GUEST_KEY,
        INVISIT_SUPPORT_KEY,  
        OLD_TAB_KEY_MAP_FOR_INVISIT_MENU} from "../../constants/tab.constants";
import AbridgeConsent from "../AddAbridge/AbridgeConsent";

const InVisitMenu = () => {
    const dispatch = useDispatch();
    const [menuList, setMenuList] = useState([]);
    const [selectedMenuKey, setSelectedMenuKey] = useState(null);

    const { appConfig: { menuItems: menuItemsConfig } } = useSelector(state => state?.[STORE_NAMES.APP]);

    const handleNavigationClick = (selectedMenuKey) => {
        setSelectedMenuKey(selectedMenuKey);
    };

    useEffect(() => {
        // support the underlying component by notifyng about which menu item is selected
        dispatch(TabAction.setCurrentTabContext(OLD_TAB_KEY_MAP_FOR_INVISIT_MENU[selectedMenuKey]));
    }, [selectedMenuKey]);

    useEffect(() => {
        const parsedMenuList = prepareInvisitMenuList(menuItemsConfig);
        setMenuList(parsedMenuList);
        const selectedMenuItem = parsedMenuList.find(menuItem => menuItem.selected===true);
        setSelectedMenuKey(selectedMenuItem.id);
    }, [menuItemsConfig]);

    return  selectedMenuKey && 
            <>
                <div className='vve-directory'>
                    <Nav variant="pills" defaultActiveKey={selectedMenuKey} onSelect={handleNavigationClick} className="vve-new-menu">
                        {menuList.map((menuItem, index) => (
                            <Nav.Item key={`${index}-${menuItem.id}`}>
                                <Nav.Link eventKey={menuItem.id}>{menuItem.title}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    {selectedMenuKey===INVISIT_ABRIDGE_KEY &&
                        <AbridgeConsent />
                    }
                    {selectedMenuKey===INVISIT_INTERPRETER_KEY &&
                        <AddInterpreter />
                    }
                    {selectedMenuKey===INVISIT_ADD_GUEST_KEY &&
                        <AddGuest />
                    }
                    {selectedMenuKey===INVISIT_SUPPORT_KEY &&
                        <AddSupport />
                    }
                </div>
            </>
};

export default InVisitMenu;