import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRadioButtonsForGuestAndPatient } from "../../helper/commonUtils";
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import personAddImage from "../../assets/images/person_add.svg";
import personAddDisabledImage from "../../assets/images/person_add_disabled.svg";
import personRemoveImage from "../../assets/images/person_remove.svg";
import errorMessageCircle from "../../assets/images/error-circle-small.svg";
import { ACTION_TYPES, STORE_NAMES } from '../../constants/store.constants';
import BUTTON_CONFIG from '../../constants/button.constants';
import {
    truncateSpecialChar,
    validateEmail,
    getFormattedPhoneNumber,
    isValidMobile,
    isAlphaNumeric,
    isIncludesAtChar,
    sliceText,
    sliceTextWithoutSplChar
} from '../../helper/commonHelper';
import {
    GUEST,
    PATIENT,
    PLACEHOLDER_MOBILE_EMAIL,
    PLACEHOLDER_MRN,
    PLACEHOLDER_NAME,
    INPUT_NUMBER_EMAIL,
    INPUT_MRN,
    INPUT_NAME,
    ERR_MSG_NUMBER_MOBILE,
    ERR_MSG_MRN_NAME,
    ERR_MSG_MRN,
    ALT_PERSON_ADD_ICO,
    ALT_PERSON_REMOVE_ICO,
    ALT_ERROR_CIRCLE_ICO,
    MULTI_INVITE_OPTIONS,
    GUEST_INVITE_EMAIL,
    GUEST_INVITE_TEXT,
} from '../../constants/guest.constants';

const AddGuestAndPatient = () => {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(GUEST);
    const [inputValues, setInputValues] = useState([]);
    const maxFields = MULTI_INVITE_OPTIONS.inviteeCountLimit;
    const { RadioButtons } = getRadioButtonsForGuestAndPatient();
    const [errorMessage, setErrorMessage] = useState();
    const { multiInviteTrackingDetailsLastSuccessful } = useSelector(state => state?.[STORE_NAMES.GUEST]);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleAddClick = () => {
        const totalFields = inputValues.length;
        let newFields = {};

        if (selectedOption === PATIENT) {
            newFields = {
                type: PATIENT,
                values: {
                    numberOrEmail: { placeholder: PLACEHOLDER_MOBILE_EMAIL, value: '' },
                    MRN: { placeholder: PLACEHOLDER_MRN, value: '' },
                    name: { placeholder: PLACEHOLDER_NAME, value: '' }
                }
            };
        } else if (selectedOption === GUEST) {
            newFields = {
                type: GUEST,
                values: {
                    numberOrEmail: { placeholder: PLACEHOLDER_MOBILE_EMAIL, value: '' }
                }
            };
        }

        if (totalFields < maxFields) {
            setInputValues([...inputValues, newFields]);
        }
    };

    const validateInputs = (fieldIndex, key, value) => {
        const values = [...inputValues];
        if (key === INPUT_NUMBER_EMAIL) {
            if (isIncludesAtChar(value)) {
                if (validateEmail(value)) {
                    values[fieldIndex].values[key].errorMessage = "";
                }
                else {
                    values[fieldIndex].values[key].errorMessage = ERR_MSG_NUMBER_MOBILE;
                }
            }
            else if (isValidMobile(value)) {
                value = getFormattedPhoneNumber(truncateSpecialChar(value));
                values[fieldIndex].values[key].errorMessage = "";
            }
            else {
                values[fieldIndex].values[key].errorMessage = ERR_MSG_NUMBER_MOBILE;
            }
        }
        if (key === INPUT_MRN) {
            value = sliceTextWithoutSplChar(value, 0, 12);
            if (value.length >= 1 && value.length <= 12 && isAlphaNumeric(value)) {
                values[fieldIndex].values[key].errorMessage = "";
            } else {
                let message = "";
                if (value.length === 0 && values[fieldIndex].values[INPUT_NAME].value.length === 0) {
                    message = ERR_MSG_MRN_NAME;
                }
                values[fieldIndex].values[key].errorMessage = message;
            }

            // If MRN is valid and name is empty, clear name's error message
            if (value.length > 0) {
                if (values[fieldIndex].values[INPUT_NAME].errorMessage === ERR_MSG_MRN_NAME) {
                    values[fieldIndex].values[INPUT_NAME].errorMessage = "";
                }
            }
        }
        if (key === INPUT_NAME) {
            if (value.length > 0) {
                value = sliceText(value, 0, 64);
                values[fieldIndex].values[key].errorMessage = "";

                // If the name is updated, also check if MRN's error should be cleared
                if (values[fieldIndex].values[INPUT_MRN].errorMessage === ERR_MSG_MRN_NAME) {
                    values[fieldIndex].values[INPUT_MRN].errorMessage = "";
                }
            } else {
                let message = "";
                if (value.length === 0 && values[fieldIndex].values[INPUT_MRN].value.length === 0) {
                    message = ERR_MSG_MRN_NAME;
                }
                values[fieldIndex].values[key].errorMessage = message;
            }
        }

        values[fieldIndex].values[key].value = value;
        setInputValues(values);
        getAndSetErrorMessage(values, fieldIndex, key);
        setInviteBtnEnable();

    }

    //Get and set error message
    const getAndSetErrorMessage = (inputArray, fieldIndex, key) => {
        //set error message - find next errormessage
        let errorMessage = null;
        if (fieldIndex && key) {
            errorMessage = inputArray[fieldIndex].values[key].errorMessage;
        }
        if (!errorMessage) {
            for (let i = 0; i < inputArray.length; i++) {
                for (let key in inputArray[i].values) {
                    if (inputArray[i].values[key].errorMessage) {
                        errorMessage = inputArray[i].values[key].errorMessage;
                        break;
                    }
                }
                if (errorMessage) {
                    break;
                }
            }
        }
        setErrorMessage(errorMessage);
    }

    const handleInputChange = (fieldIndex, key, event) => {
        let { value } = event.target;
        validateInputs(fieldIndex, key, value);
    };

    const handleOnPaste = (fieldIndex, key, event) => {
        event.preventDefault();
        const paste = event.clipboardData.getData('text');
        validateInputs(fieldIndex, key, paste);
    }

    const handleOnFocus = (fieldIndex, key) => {
        //set error message
        const values = [...inputValues];
        setErrorMessage(values[fieldIndex].values[key].errorMessage);

        //set error message if any
        if (!values[fieldIndex].values[key].errorMessage)
            getAndSetErrorMessage(values, fieldIndex, key);
    }

    const handleRemoveClick = (fieldIndex) => {
        const values = [...inputValues];
        values.splice(fieldIndex, 1);
        setInputValues(values);

        //Get or set error message while removing field
        getAndSetErrorMessage(values, fieldIndex);
    };

    const setInviteBtnEnable = () => {
        const inputArray = [...inputValues];
        let enableInviteButtonStatus = false;
        let errorMessage = false;

        for (let i = 0; i < inputArray.length; i++) {
            const role = inputArray[i].type;
            const values = inputArray[i].values;

            for (let key in values) {
                const value = values[key].value.trim();
                const error = values[key].errorMessage;

                if (value.length === 0 || error) {
                    errorMessage = true;
                }

                if (key == INPUT_MRN && value.length == 0 && values[INPUT_NAME].value.length > 0) {
                    errorMessage = false;
                }

                if (key == INPUT_NAME && value.length == 0 && !values[INPUT_MRN].errorMessage) {
                    errorMessage = false;
                }
                if (errorMessage) {
                    break;
                }
            }

            if (errorMessage) {
                break;
            }
        }

        enableInviteButtonStatus = inputArray.length > 0 && !errorMessage;
        dispatch({ type: ACTION_TYPES.ENABLE_INVITE_BUTTON, payload: enableInviteButtonStatus });
    };

    // prepare and save invitee notification payload 
    const updateInviteeNotificationPayload = () => {
        const storeData = [];
        const inputArray = [...inputValues];

        let inviteGuestPatientObj = null;
        for (let i = 0; i < inputArray.length; i++) {
            inviteGuestPatientObj = {};
            for (let key in inputArray[i].values) {
                inviteGuestPatientObj.role = inputArray[i].type;

                if (key == INPUT_NUMBER_EMAIL && inputArray[i].values[key].value.length > 0 && inputArray[i].values[key].errorMessage == "") {
                    let value = inputArray[i].values[key].value;
                    let notificationType = isValidMobile(value) ? GUEST_INVITE_TEXT : GUEST_INVITE_EMAIL;
                    let notificationId = (notificationType === GUEST_INVITE_TEXT) ? truncateSpecialChar(value) : value.trim();
                    inviteGuestPatientObj.notificationId = notificationId;
                    inviteGuestPatientObj.notificationType = notificationType;
                }
                if (inputArray[i].type == PATIENT) {
                    if (key == INPUT_MRN && inputArray[i].values[key].errorMessage == "") {
                        inviteGuestPatientObj[key.toLocaleLowerCase()] = inputArray[i].values[key].value;
                    }
                    if (key == INPUT_NAME) {
                        inviteGuestPatientObj[key.toLocaleLowerCase()] = inputArray[i].values[key].value ? inputArray[i].values[key].value : inputArray[i].values[INPUT_NUMBER_EMAIL].value;
                    }
                }
            }
            storeData.push(inviteGuestPatientObj);
        }

        dispatch({ type: ACTION_TYPES.SET_MULTI_GUEST_PATIENT_INVITEES, payload: storeData });
    };

    useEffect(() => {
        if (multiInviteTrackingDetailsLastSuccessful && multiInviteTrackingDetailsLastSuccessful.length > 0) {
            const formValues = [...inputValues];
            // Filter out Controls where values.numberOrEmail.value matches a notificationId in the Store array
            const filteredControls = formValues.filter(control =>
                !multiInviteTrackingDetailsLastSuccessful.some(storeItem => storeItem.notificationId === control.values.numberOrEmail.value)
            );
            setInputValues(filteredControls);
        }
    }, [multiInviteTrackingDetailsLastSuccessful])

    useEffect(() => {
        // Enable or disable invite button 
        setInviteBtnEnable();

        //update store
        updateInviteeNotificationPayload();
    }, [inputValues]);

    return (
        <Form className='vve-invite-guest-patient'>
            <Row className="align-items-center">
                <Col xs="auto">
                    <div key="default-radio" className="p-3">
                        {RadioButtons.map((radioButton, index) => {
                            const btnIndex = `radio-${index}`;
                            return <Form.Check
                                key={btnIndex}
                                type="radio"
                                label={radioButton.label}
                                title={radioButton.title}
                                name={radioButton.name}
                                value={radioButton.value}
                                defaultChecked={radioButton.checked}
                                onChange={handleOptionChange}
                                className={`vve-add-guest-patient-radiobtn ${inputValues.length >= 5 ? 'disabled' : ''}`}
                                inline
                            />
                        })}
                    </div>
                </Col>

                <Col xs="auto">
                    <Button variant="primary"
                        className={`vve-invite-guest-patient-btnadd ${inputValues.length >= 5 ? 'disabled' : ''}`}
                        onClick={handleAddClick}>
                        <img
                            src={`${inputValues.length >= 5 ? personAddDisabledImage : personAddImage}`}
                            alt={ALT_PERSON_ADD_ICO}
                            className="me-2"
                        />
                        {BUTTON_CONFIG.ADD_BUTTON.title}
                    </Button>
                </Col>
            </Row>
            <div className='vve-invite-guest-patient-container'>
                {inputValues.map((field, fieldIndex) => (
                    <div key={fieldIndex}>
                        {Object.keys(field.values).map((key) => (
                            <div key={key} className='vve-invite-guest-patient-tbgroup'>
                                <InputGroup className="vve-form-tb-search">
                                    <Form.Control
                                        type="search"
                                        placeholder={field.values[key].placeholder}
                                        value={field.values[key].value}
                                        onChange={(e) => handleInputChange(fieldIndex, key, e)}
                                        onPaste={(e) => handleOnPaste(fieldIndex, key, e)}
                                        onFocus={(e) => handleOnFocus(fieldIndex, key, e)}
                                        className={`vve-invite-guest-patient-tb ${(field.values[key].errorMessage) ? "vve-invite-validation-error" : ""}`}
                                    />
                                </InputGroup>
                            </div>
                        ))}

                        <Button variant="link"
                            className="vve-invite-guest-patient-btnremove"
                            onClick={() => handleRemoveClick(fieldIndex)}>
                            <img
                                src={personRemoveImage}
                                alt={ALT_PERSON_REMOVE_ICO}
                                className="me-2"
                            />
                            {BUTTON_CONFIG.REMOVE_BUTTON.title}
                        </Button>
                    </div>
                ))}

                {errorMessage &&
                    <div className='vve-invite-error-message'>
                        <img
                            src={errorMessageCircle}
                            alt={ALT_ERROR_CIRCLE_ICO}
                            className="me-2"
                        />
                        <label>{errorMessage}</label>
                    </div>
                }
            </div>
        </Form>
    );
};

export default AddGuestAndPatient;
